<template>
  <div id="container">
    <div id="name">{{ loginData.author ? loginData.author.nickName : "" }}</div>
    <div id="notice" v-if="showNotice" ref="nout">
      <i class="el-icon-sunny"/>
      <template @click="copyContent(noticeContent)">
        <marquee @click="copyContent(noticeContent)" scrollamount="5" v-if="needMarquee">{{ noticeContent }}</marquee>
        <div @click="copyContent()" class="notice_content" v-else ref="nin">{{ noticeContent }}</div>
      </template>
      <div style="font-size: 22px;padding-left: 10px;" @click="showNotice=false">×</div>
    </div>
    <div class="line"></div>
    <div
        ref="box_container"
        style="display: flex;flex-direction: column;height: 0px;flex:1;overflow: auto;padding: 1rem;box-sizing: border-box">
      <div v-for="citem in messages">
        <template v-if="citem.type<=4">
          <!--          自己发送-->
          <div v-if="citem.sendId==loginData.id"
               style="display: flex;margin: 10px 0;align-items: flex-start;justify-content: flex-end;">

            <template v-if="citem.type==0">
              <div class="text self" v-html="getContent(citem.content)"></div>
            </template>

            <template v-if="citem.type==1">
              <img class="self" @click.stop="showPic(citem)"
                   style="border-radius:.4rem;margin-left:0.6rem;max-width: 40%;max-height: 40%;"
                   :src="getThumbUrl(citem)">
            </template>

            <template v-if="citem.type==2">
              <div class="self" @click="download(citem)"
                   style="color:#53a0e7;background:white;padding:10px;box-sizing:border-box;border-radius:.4rem;margin-left:0.6rem;max-width: 70%;font-size:12px;max-height: 40%;">
                <div style="font-size: 12px;">{{ getFileInfo(citem.content).name }}</div>
                <div style="font-size: 12px;margin-top: 5px;text-align: right;color: #4cae1b">
                  {{ (getFileInfo(citem.content).size / 1024).toFixed(1) }} kb
                </div>
              </div>
            </template>

            <template v-if="citem.type==4">
              <span class="self" @click.stop="showVideo(citem)"
                    style="position: relative;padding-left: 0.6rem;box-sizing: border-box;background: none;">
                <img
                    style="border-radius:.4rem;max-width: 12rem;max-height: 15rem;"
                    :src="getThumbUrl(citem)">
                <i class="el-icon-video-play"
                   style="font-size:2.5rem;color: #cecece;position: absolute;left: 50%;top: 50%;transform: translate(calc(-50% + 0.3rem),calc(-50% - 0.3rem))"/>
              </span>
            </template>

            <img class="head" :src="require('@/assets/image/default_head.jpeg')">
          </div>
          <!--        对方或系统发送-->
          <div v-else
               style="display: flex;margin: 10px 0;align-items: flex-start;justify-content: flex-start;">
            <img class="head"
                 :src="(loginData.author!=null&&loginData.author.headImageThumb)?loginData.author.headImageThumb:require('@/assets/image/default_head.jpeg')">
            <template v-if="citem.type==0">
              <div class="text other" v-html="getContent(citem.content)"></div>
            </template>

            <template v-if="citem.type==1">
              <img @click.stop="showPic(citem)"
                   style="border-radius:.4rem;margin-left:0.6rem;max-width: 40%;max-height: 40%;"
                   :src="getThumbUrl(citem)">
            </template>

            <template v-if="citem.type==2">
              <div @click="download(citem)"
                   style="color:#53a0e7;background:white;padding:10px;box-sizing:border-box;border-radius:.4rem;margin-left:0.6rem;max-width: 70%;font-size:12px;max-height: 40%;">
                <div style="font-size: 12px;">{{ getFileInfo(citem.content).name }}</div>
                <div style="font-size: 12px;margin-top: 5px;text-align: right;color: #4cae1b">
                  {{ (getFileInfo(citem.content).size / 1024).toFixed(1) }} kb
                </div>
              </div>
            </template>

            <template v-if="citem.type==3">
              <audio style="border-radius:.4rem;margin-left:0.6rem;" controls :src="getAudioSrc(citem)"></audio>
            </template>

            <template v-if="citem.type==4">
              <span @click.stop="showVideo(citem)"
                    style="position: relative;padding-left: 0.6rem;box-sizing: border-box">
                <img
                    style="border-radius:.4rem;max-width: 12rem;max-height: 15rem;"
                    :src="getThumbUrl(citem)">
                <i class="el-icon-video-play"
                   style="font-size:2.5rem;color: #cecece;position: absolute;left: 50%;top: 50%;transform: translate(calc(-50% + 0.3rem),calc(-50% - 0.3rem))"/>
              </span>
            </template>
          </div>
        </template>
        <template v-if="citem.type==10">
          <div style="display: flex;align-items: center;justify-content: center;color: #5c6b77;padding: 10px 0;font-size: 14px;">{{citem.content}}</div>
        </template>
      </div>
    </div>

    <div class="line"></div>
    <div class="input_area">
      <div contenteditable="true" rows="1" @input="inputText" ref="input" class="user_input"
           auto-height="true"></div>
      <div style="display: flex;align-items: center;justify-content: center">
        <div id="emoji" class="icon iconfont icon-emoji" @click="showSelEmoji"/>
        <div id="send" v-if="content" @click="sendMessageToAuthor()">发送</div>
        <i v-else class="el-icon-circle-plus-outline" id="more" @click="showEmoji=false;showMore=!showMore"></i>
      </div>
    </div>


    <div v-if="showEmoji" id="emoji_sels">
      <div class="item_emoji_sel" v-for="(emoText, i) in $emo.emoTextList" :key="i"
           @click="onClickEmo(emoText)" v-html="$emo.textToImg(emoText)">
      </div>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
      <img class="blank"/>
    </div>
    <div v-if="showMore" id="more_dv">
      <div class="more_item" v-for="item in moreConfigs" @click="beginSend(item)">
        <i :class="item.icon" class="more_icon"/>
        <div class="more_name">{{ item.name }}</div>
      </div>
    </div>


    <div class="show_orig_img" @click.stop="origImgUrl=null" v-if="origImgUrl">
      <img style="width: 100%;height: auto;" :src="origImgUrl">
    </div>

    <div class="show_orig_img" v-if="videoUrl">
      <video style="max-height:100%;max-width: 100%;height: auto;" loop controls autoplay :src="videoUrl"/>
      <div @click="videoUrl=null;" style="color: white;position: absolute;top: 1.5rem;right: 1.5rem;font-size: 2rem;">
        ×
      </div>
    </div>

    <div id="errorShow" v-if="errMsg">
      <div v-if="banMsg" class="ban_div">
        <div>您的账号因</div>
        <div id="ban_msg">{{ banMsg }}</div>
        <div>已被客服封禁！</div>
      </div>
      <div v-else style="color: #ec1f59">{{ errMsg }}</div>
    </div>

    <input style="width: 0;height: 0" @change="uploadImageEvent($event,1)"
           accept="image/jpeg,image/png,image/jpg,image/webp,image/gif"
           type="file" ref="fileupload">

    <input style="width: 0;height: 0" @change="uploadImageEvent($event,4)" accept="video/mp4"
           type="file" ref="videoupload">
  </div>
</template>

<script>
import ChatMessageItem from "../components/chat/ChatMessageItem.vue";
import * as socketApi from '../api/wssocket';
import axios from "axios";

export default {
  name: "Talk",
  components: {ChatMessageItem},
  data() {
    return {
      noticeContent: "",
      banMsg: "",
      errMsg: "正在加载数据，请稍候。。。",
      origImgUrl: "",
      loginData: {},
      videoUrl: "",
      showNotice: false,
      needMarquee: false,
      socket: null,
      authorHead: "authorHead",
      moreConfigs: [
        {
          name: '图片',
          type: 1,
          icon: "el-icon-picture-outline"
        },
        {
          name: '视频',
          type: 2,
          icon: "el-icon-camera"
        },
        {
          name: '位置',
          type: 3,
          icon: "el-icon-location-outline"
        },
      ],
      showEmoji: false,
      showMore: false,
      mine: {
        id: 1
      },
      showMinIdx: 0,
      messages: [],
      content: "",
    }
  },
  methods: {
    showSelEmoji() {
      this.showMore = false;
      this.showEmoji = !this.showEmoji
      let div = this.$refs.input;
      div.focus();

      let range = document.createRange();
      range.setStart(div, 0); // 0表示从div的起始位置开始
      range.setEnd(div, 0); // 0表示到div的起始位置结束

      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // 移动光标到div的任意位置，例如第3个字符

      try {
        range.setStart(div.firstChild, div.innerHTML.length); // 假设div只有一个子节点，即文本节点
        range.collapse(true); // 折叠范围到起始点
        selection.removeAllRanges();
        selection.addRange(range);
      } catch (e) {
      }
    },
    getRange() {
      let selection = window.getSelection();
      return selection.getRangeAt(0);
    },
    onClickEmo(emojiText) {
      this.$refs.input.selectionStart = this.$refs.input.innerHTML.length;
      let encodeEMojiText = `#${emojiText};`
      let emojiElement = document.createElement('img');
      emojiElement.className = 'chat-emoji no-text';
      emojiElement.dataset.emojiCode = encodeEMojiText;
      emojiElement.src = this.$emo.textToUrl(encodeEMojiText);

      let blurRange = this.getRange();
      if (blurRange.startContainer !== blurRange.endContainer || blurRange.startOffset !== blurRange.endOffset) {
        blurRange.deleteContents();
      }
      blurRange.insertNode(emojiElement);
      blurRange.collapse()
      this.content += encodeEMojiText;
    },
    inputText() {
      let input = this.$refs.input;
      input.style.height = 'auto'
      input.style.height = input.scrollHeight + "px"
      let data = this.$refs.input.innerHTML;
      this.content = data;
    },
    getUrl(str) {
      const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      const res = [];
      const strValue = str.match(reg);
      if (strValue && strValue.length > 0) {
        return strValue;
      }
      return res;
    },
    getContent(val) {
      let res = this.$emo.transform(val)
      let url = this.getUrl(res);
      if (url.length > 0) {
        for (let u of url) {
          res = res.replace(u, `<a href="${u}" target="_blank">${u}</a><span class="cpbutton" onclick="copyUrl('${u}')">复制</span>`)
        }
      }
      return res;
    },
    async uploadImageEvent(data, type = 1) {
      const formData = new FormData();
      formData.append("file", data.target.files[0]);
      let url = "image/upload"
      if (type == 4) {
        url = "video/upload"
      }
      data.target.value = null;
      const res = await axios.post(process.env.VUE_APP_BASE_API + url, formData, {
        headers: {
          accessToken: this.loginData.accessToken
        }
      });
      if (res.data.code != 200) {
        this.$alert(res.data.message)
        return;
      }
      //originUrl thumbUrl
      this.sendMessageToAuthor(JSON.stringify(res.data.data), type)
    },
    beginSend(item) {
      if (item.type == 1) {
        this.$refs.fileupload.click();
      }
      if (item.type == 2) {
        this.$refs.videoupload.click();
      }
    },
    getAudioSrc(data) {
      let res = JSON.parse(data.content).url;
      return res;
    },
    download(item) {
      let url = JSON.parse(item.content).url;
      window.location.href = url;
    },
    async sendMessageToAuthor(con, type = 0) {
      try {
        const data = {
          recvId: this.loginData.author.id,
          content: con,
          //消息类型 0:文字 1:图片 2:文件 3:语音 4:视频
          type: type
        };
        if (con == null) {
          let sendData = "";
          let nodes = this.$refs.input.childNodes;
          for (let node of nodes) {
            if (node.nodeType == 3) {
              sendData += this.$util.html2Escape(node.textContent);
            } else if (node.nodeName === 'IMG') {
              let emojiCode = node.dataset.emojiCode;
              sendData += emojiCode;
            }
          }
          this.content = ""
          data.content = sendData;
          this.$refs.input.innerHTML = ""
        }
        const res = await axios.post(process.env.VUE_APP_BASE_API + "message/private/send", data, {
          headers: {
            accessToken: this.loginData.accessToken
          }
        });
        if (res.data.code == 200) {
          this.messages.push(res.data.data);
          this.scrollToBottom()
        }
      } catch (e) {
        console.log(e);
      }
    },
    getFileInfo(data) {
      const r = JSON.parse(data);
      return r;
    },
    scrollToBottom() {
      console.log("scrollbottom")
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.box_container.scrollTop = this.$refs.box_container.scrollHeight;
        }, 50)
      })
    },
    selEmoji(item, index) {
      console.log(index)
    },
    showName(info) {
    },
    headImage(info) {

    },
    getSelfId() {
      let res = localStorage.getItem("selfId");
      if (!res || res == 'undefined') {
        return "";
      }
      return res;
    },
    async pullPrivateOfflineMessage(minId) {
      try {
        await axios.get(process.env.VUE_APP_BASE_API + "message/private/pullOfflineMessage?minId=" + minId, {
          headers: {
            accessToken: this.loginData.accessToken
          }
        });
      } catch (e) {
      }
    },
    //获取消息中的缩略图地址
    getThumbUrl(msg) {
      let parse = JSON.parse(msg.content);
      return parse.thumbUrl
    },
    //查看大图
    showPic(msg) {
      let parse = JSON.parse(msg.content);
      let url = parse.originUrl;
      this.origImgUrl = url;
    },
    //查看视频
    showVideo(msg) {
      let parse = JSON.parse(msg.content);
      let url = parse.originUrl;
      this.videoUrl = url;
    },
    initSocket(data) {
      console.log("initsocket", data, this.errMsg)
      this.socket = socketApi;
      if (!data) {
        return;
      }
      this.socket.onConnect(() => {
        this.errMsg = null
        // 加载离线消息
        console.log("connected------------")
        this.pullPrivateOfflineMessage(0);
      });
      this.socket.onMessage((cmd, msgInfo) => {
        if (cmd == 2) {
          // 关闭ws
          this.socket.close(3000)
          // 异地登录，强制下线
          /*this.$alert("您已在其他地方登陆，将被强制下线", "强制下线通知", {
            confirmButtonText: '确定',
            callback: action => {
              location.href = "/";
            }
          });*/
        } else if (cmd == 3) {
          // 插入私聊消息
          // this.handlePrivateMessage(msgInfo);
          if (msgInfo.type==10){
            this.messages.map((item,index)=>{
              if (item.id==msgInfo.id){
                item.type=msgInfo.type;
                item.content=msgInfo.content
                return;
              }
            });
          }else{
            this.messages.push(msgInfo)
          }
          this.scrollToBottom()
        } else if (cmd == 5) {
          // 处理系统消息
          // this.handleSystemMessage(msgInfo);
          console.log("system", msgInfo)
          if (msgInfo.type == 50) {
            window.location.reload()
          }
        }
      });
      this.socket.onClose((e) => {
        if (e.code != 3000) {
          // 断线重连
          this.$message.error("连接断开，正在尝试重新连接...");
          this.$wsApi.reconnect(`ws://${location.hostname}:8878/im`, this.loginData.accessToken);
        }
      });
      this.socket.connect(`ws://${location.hostname}:8878/im`, data.accessToken);
    },
    async onPageShow() {
      if (!this.loginData.id) {
        return;
      }
      axios.put(process.env.VUE_APP_BASE_API + "message/private/readed?friendId=" + this.loginData.author.id, {}, {
        headers: {
          accessToken: this.loginData.accessToken
        }
      })
    },
    initNotice() {
      if (this.loginData.author && this.loginData.author.signature) {
        this.noticeContent = this.loginData.author.signature
        this.showNotice = true;
      }
      this.$nextTick(() => {
        let noutWidth = this.$refs.nout.clientWidth
        let ninWidth = this.$refs.nin.scrollWidth
        if (ninWidth > noutWidth - 40) {
          this.needMarquee = true;
        }
      })
    },
    copyContent(data){
      this.$message({
        type:"success",
        message:"已复制"
      })
      navigator.clipboard.writeText(data)
    },
    async beginTalkToAnchor() {
      try {
        const res = await axios.post(process.env.VUE_APP_BASE_API + "talkTo", {
          targetId: parseInt(this.$route.params.id),
          selfId: this.getSelfId()
        })
        if (res.data.code != 200) {
          if (res.data.code == 666) {
            this.banMsg = res.data.message;
          } else {
            this.errMsg = res.data.message
          }
          return;
        }
        let data = res.data.data;
        localStorage.setItem("selfId", data.clientId)
        this.loginData = data;
        this.errMsg = "正在初始化连接。。。"
        this.initSocket(data);
        this.initNotice();
      } catch (e) {
        this.$message({
          type: "error",
          message: "加载失败 " + e
        })
      }
    }
  },
  created() {
    this.beginTalkToAnchor();
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        this.onPageShow();
      }
    });
    window.copyUrl=this.copyContent
  }
}
</script>

<style scoped>
* {
  font-size: 16px;
}

#container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #ececec;
}

.head {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.3rem;
}

.text {
  margin-left: 0.6rem;
  background: #ef9c9c;
  max-width: calc(100% - 6rem);
  word-break: break-all;
  padding: 0.8rem;
  box-sizing: border-box;
  font-size: 1rem;
  border-radius: 0.4rem;
}

.other {
  background: #ffffff;
}

.self {
  background: #f6d291;
  margin-right: 0.6rem;
  margin-left: 0;
}

#name {
  text-align: center;
  padding: 0.8rem 0;
  background: #f5f4f4;
}

.line {
  width: 100%;
  height: 1px;
  background: #cdcccc;
  left: 0;
  right: 0;
}

.input_area {
  background: #f5f4f4;
  display: flex;
  padding: 0.8rem;
  align-items: flex-end;
}

.user_input {
  outline: none;
  background: none;
  border: none;
  height: auto;
  background: white;
  max-height: 5rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
}

#emoji {
  margin-left: 0.6rem;
  font-size: 2rem;
}

#send {
  background: #ffbc23;
  color: white;
  padding: 0.3rem 1rem;
  font-size: 1rem;
  margin-left: 0.6rem;
  border-radius: 0.3rem;
}

#more {
  font-size: 2rem;
  margin-left: 0.6rem;
}

#emoji_sels {
  overflow: auto;
  max-height: 40%;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.item_emoji_sel {
  width: 2.5rem;
  height: 2.5rem;
}

.blank {
  width: 2.5rem;
  height: 0;
}

#more_dv {
  display: flex;
}

.more_item {
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

.more_name {
  text-align: center;
  margin-top: 0.2rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.more_icon {
  font-size: 2rem;
  background: white;
  padding: 0.8rem;
  border-radius: 0.4rem;
}

.show_orig_img {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .8);
  position: absolute;
  left: 0;
  padding: 1rem;
  box-sizing: border-box;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#errorShow {
  background: #e0e0e0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  position: absolute;
}

.ban_div {
  line-height: 100%;
  color: #474747;
  background: #ead440;
  padding: 20px;
  width: 90%;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
}

#ban_msg {
  color: #c70b0b;
  margin: 20px 10px;
  font-weight: 900;
  font-size: 18px;
}

#notice {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  padding: 5px;
  box-sizing: border-box;
  background: #f6d8aa;
  color: #ea7d20;
}

.notice_content {
  flex: 1;
  margin-left: 10px;
  box-sizing: border-box;
  color: #bb350c;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
}

marquee {
  flex: 1;
  margin-left: 10px;
  color: #bb350c;
  font-size: 12px;
}
</style>
<style>
.chat-emoji {
  width: 25px !important;
  height: 25px !important;
}
.cpbutton{
  margin-left: 5px;
  font-size: 14px;
  color: #ffffff;
  background: #4cae1b;
  padding: 2px 5px;
  border-radius: 3px;
}
</style>